/* Include fonts
 @mixin ======================================================================== */
@include fontFace('Open Sans', 'OpenSans-Regular', 400, 'normal', 'woff');
@include fontFace('Open Sans', 'OpenSans-Regular', 400, 'normal', 'woff2');
@include fontFace('Open Sans', 'OpenSans-Regular', 400, 'normal', 'ttf');

@include fontFace('Open Sans', 'OpenSans-SemiBold', 600, 'normal', 'woff');
@include fontFace('Open Sans', 'OpenSans-SemiBold', 600, 'normal', 'woff2');
@include fontFace('Open Sans', 'OpenSans-SemiBold', 600, 'normal', 'ttf');

@include fontFace('Open Sans', 'OpenSans-Bold', 700, 'normal', 'woff');
@include fontFace('Open Sans', 'OpenSans-Bold', 700, 'normal', 'woff2');
@include fontFace('Open Sans', 'OpenSans-Bold', 700, 'normal', 'ttf');

@include fontFace('Montserrat', 'Montserrat-SemiBold', 600, 'normal', 'woff');
@include fontFace('Montserrat', 'Montserrat-SemiBold', 600, 'normal', 'woff2');
@include fontFace('Montserrat', 'Montserrat-SemiBold', 600, 'normal', 'ttf');

@include fontFace('Montserrat', 'Montserrat-Bold', 700, 'normal', 'woff');
@include fontFace('Montserrat', 'Montserrat-Bold', 700, 'normal', 'woff2');
@include fontFace('Montserrat', 'Montserrat-Bold', 700, 'normal', 'ttf');

@include fontFace('Montserrat', 'Montserrat-ExtraBold', 800, 'normal', 'woff');
@include fontFace('Montserrat', 'Montserrat-ExtraBold', 800, 'normal', 'woff2');
@include fontFace('Montserrat', 'Montserrat-ExtraBold', 800, 'normal', 'ttf');
