$accent: #000000;
$accent-1: #ffffff;
$accent-2: #3055D8;
$accent-3: #EC221F;

$font-family-base: "Open Sans";

$big: "1360px";
$lg: "1279px";
$sm: "1023px";
$md: "767px";
