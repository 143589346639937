.contacts {
    padding: 66px 0;

    @media (max-width: $lg) {
        padding: 46px 0; }

    iframe {
        max-width: 541px;
        border-radius: 30px;

        @media (max-width: $lg) {
            width: 100%; }

        @media (max-width: $md) {
            margin: 0 auto;
            max-width: calc( 100vw - 80px );
            max-height: calc( 100vw - 140px ); } }

    .container-main {
        display: flex;
        align-items: center;
        gap: 95px;

        @media (max-width: $lg) {
            flex-wrap: wrap; }

        @media (max-width: $md) {
            flex-direction: column-reverse;
            gap: 32px;
            align-items: flex-start; } }

    &__text {
        @media (max-width: $md) {
            max-width: 100%;
            width: 100%; }
        h2 {
            margin-bottom: 30px; }

        a,div {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            gap: 16px;

            p {
                font-size: 18px;

                @media (max-width: $md) {
                    font-size: 14px; } } }

        .button {
            margin-top: 30px;
            margin-bottom: 0;

            @media (max-width: $md) {
                max-width: 100%;
                margin-top: 24px; } } }

    img {
        border-radius: 30px; } }
