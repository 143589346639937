.header {
    width: 100%;
    background: #F7F7F7;

    .block {
        display: flex;
        align-items: center; }

    .button {
        min-width: 204px;
        margin-left: 35px;
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        background: $accent-3;

        &:hover {
            background: $accent-2; } }

    .container-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 144px;

        @media (max-width: $lg) {
            height: 80px;
            padding: 0 20px; } }

    @media (max-width: $lg) {
        .logo {
            svg {
                width: 50px;
                height: 50px; } } }

    &__mob {
        display: none;

        @media (max-width: $lg) {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: auto; }

        .tel {
            min-width: 36px;
            height: 36px;
            border-radius: 100%;
            background: #C5D1F8;
            display: flex;
            align-items: center;
            justify-content: center; }

        .file {
            margin: 0;
            max-width: 88px;
            min-width: 88px;
            font-size: 14px;
            font-weight: 600; } }

    &__desk {
        display: flex;
        align-items: center;

        @media (max-width: $lg) {
            display: none; } }

    &__main {
        display: flex;
        align-items: center; }

    &__phone {
        margin-left: 65px;
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
        color: $accent;

        &:hover {
            color: $accent-2; } }

    &__social {
        margin-left: 35px; }

    .mainmenu--js {
        &.is-active {
            left: 0;
            transition: transform .3s ease; }

        @media (max-width: $lg) {
            margin-left: 0;
            min-width: 100vw;
            width: 100%;
            left: calc( -100vw - 40px );
            height: 100vh;
            position: absolute;
            background: $accent-2;
            backdrop-filter: blur(10px);
            top: 0;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 80px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start; }

        >a {
            display: none;
            @media (max-width: $lg) {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 20px;

                p {
                    color: white;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19px; } } }

        .button {
            display: none;
            @media (max-width: $lg) {
                display: flex;
                max-width: 400px;
                margin-bottom: 0;
                margin-top: 20px;
                color: black;
                background: white;
                margin-left: 0; }

            @media (max-width: 500px) {
                max-width: calc( 100% - 40px ); } } }

    &__nav {
        ul {
            display: flex;
            align-items: center;
            gap: 40px;

            @media (max-width: 1380px) {
                gap: 30px; }

            @media (max-width: $lg) {
                flex-direction: column;
                gap: 30px;
                align-items: flex-start;
                margin-bottom: 30px; }

            a {
                color: $accent;
                font-weight: 700;
                font-size: 18px;
                white-space: nowrap;

                &:hover {
                    color: $accent-2; }

                @media (max-width: $lg) {
                    color: white;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding-left: 7px;
                    font-weight: 600; }

                &::before {
                    @media (max-width: $lg) {
                        display: block;
                        content: '';
                        background: white;
                        width: 8px;
                        height: 8px;
                        border-radius: 100%; } } } } }

    &__hamburger {
        display: none;

        @media (max-width: $lg) {
            display: block; } } }
