body {
    background: $accent-1;
    font-family: "Open Sans";
    margin: 0;
    font-weight: 400; }

.page {
    overflow-x: initial;
    @media (min-width: 1921px) {
        max-width: none !important; }

    &__content {
        > .container-main {
            display: flex;
            gap: 20px; } } }

.container-main {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;

    @media (max-width: $lg) {
        padding: 0 20px; } }

.main {
    display: flex;
    flex-direction: column; }

.section-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 49px;
    margin: 0;

    @media (max-width: $lg) {
        font-size: 24px;
        line-height: 32px; } }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: $accent-1;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
    height: 43px;
    background: $accent-2;
    white-space: nowrap;
    max-width: 204px;
    transition: all 0.7s ease;
    border-radius: 5px;

    &:hover {
        color: $accent-1;
        background: $accent-3; } }

table {
    border-collapse: collapse; }

p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 156%;
    color: $accent; }

a {
    text-decoration: none;
    transition: all 0.7s ease; }

img {
    object-fit: cover;
    width: 100%; }

button {
    border: none;
    transition-property: all;
    transition-duration: .6s;
    padding: 0;
    background: none;

    &:hover {
        opacity: 0.8; } }

@keyframes opacity {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.swiper-button-next, .swiper-button-prev {
    cursor: pointer; }

.slider-btns {
    display: flex;
    align-items: center;
    gap: 25px;

    @media (max-width: $md) {
        gap: 12px; }

    >button {
        width: 43px;
        height: 43px;
        border-radius: 5px;
        background: $accent-2;

        &:hover {
            color: $accent-1;
            background: $accent-3; }

        @media (max-width: $md) {
            width: 32px;
            height: 32px;

            svg {
                width: 6.5px; } } } }
.modal {
    position: fixed;
    top: 0;
    z-index: 11;

    &.fade {
        display: none; } }

.modal {
    min-width: 100vw;
    height: 100vh;
    background: #0000008e; }

.modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    >div {
        position: relative;

        .btn-close {
            position: absolute;
            right: 24px;
            top: 24px;
            cursor: pointer; } } }

.aboutModal {
    max-width: 1000px;
    background: white;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc( 100vh - 60px );
    overflow-y: auto;

    @media (max-width: $md) {
        max-height: calc( 100vh - 80px );
        max-width: calc( 100vw - 80px );
        height: 100%;
        padding: 24px; }

    h2,h3, p {
        margin: 0; }

    h2 {
        color: $accent-2;
        font-size: 26px;
        line-height: 32px;
        font-weight: 700;

        @media (max-width: $md) {
            font-size: 22px;
            line-height: 28px; } }

    h3 {
        font-size: 22px;
        line-height: 30px;
        color: #000000CC;

        @media (max-width: $md) {
            font-size: 20px;
            line-height: 26px; } }

    p, li {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: #00000099; }

    ul {
        display: flex;
        flex-direction: column;
        list-style: disc;
        gap: 4px;
        padding-left: 24px; } }
