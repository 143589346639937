.vacancies {
    padding-top: 85px;
    padding-bottom: 50px;

    @media (max-width: $lg) {
        padding: 46px 0; }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;

        @media (max-width: $md) {
            margin-bottom: 24px; }

        h2 {
            margin: 0; } }

    .swiper-slide {
        h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600; } }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;

        div {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                border-radius: 100%;
                width: 16px;
                height: 16px; }

            &:first-child {
                span {
                    background: #6697DB; } }

            &:nth-child(2) {
                span {
                    background: #99B1D1; } }

            &:nth-child(3) {
                span {
                    background: #C5DDE0; } } } }

    &__sub-title {
        position: relative;
        min-height: 75px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 16px;

        @media (max-width: $md) {
            min-height: auto; }

        &::after {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            height: 1px;
            content: '';
            background: #CECECE; } }

    .button {
        margin-top: 56px;
        margin-left: auto;

        @media (max-width: $md) {
            margin-top: 24px;
            max-width: 100%; } } }
