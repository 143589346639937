.about {
    position: relative;
    background: linear-gradient(84.27deg, rgba(250, 241, 237, 0.5) 0%, #E6F2FF 100%);
    padding: 65px 0;

    @media (max-width: $md) {
        padding-top: 30px;
        padding-bottom: 68px; }

    picture {
        width: 100%;
        max-width: 614px;
        @media (max-width: $md) {
            margin-top: 24px;

            img {

                max-height: 205px; } } }

    .container-main {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 120px;

        @media (max-width: $big) {
            gap: 60px; }

        @media (max-width: $lg) {
            gap: 36px; }

        @media (max-width: $md) {
            flex-wrap: wrap; } }

    &__text {
        max-width: 705px;

        h2 {
            margin-bottom: 20px;

            @media (max-width: $md) {
                margin-bottom: 24px; } }

        strong {
            font-size: 22px;
            line-height: 24px;
            font-weight: 700;

            @media (max-width: $md) {
                font-size: 18px; } }

        p {
            font-size: 20px;
            line-height: 24px;

            @media (max-width: $md) {
                font-size: 14px; } }

        .button {
            margin-top: 45px;

            @media (max-width: $md) {
                margin-top: 0;
                position: absolute;
                bottom: 0; }

            @media (max-width: $md) {
                max-width: calc( 100% - 40px ); } } } }
