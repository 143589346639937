// Starter template
@import './page/page';
@import "./hamburger/hamburger";
@import "./module/module";

// Components
@import "./footer/footer";
@import "./header/header";
@import "./overlay/overlay";
@import "./text-block/text-block";

// Sections
@import "./sections/promo/promo";
@import "./sections/about/about";
@import "./sections/vacancies/vacancies";
@import "./sections/navy/navy";
@import "./sections/contacts/contacts";
