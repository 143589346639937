.footer {
    background: #F7F7F7;
    display: flex;
    align-items: center;
    height: 270px;

    @media (max-width: $md) {
        height: auto;
        padding: 32px 0; }

    .container-main {
        width: 100%;
        display: flex;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: center;
            gap: 24px; } }

    .button {
        margin-left: auto;
        max-width: 180px;

        @media (max-width: $lg) {
            margin-left: initial;
            max-width: 100%; } }

    .logo-desk {
        @media (max-width: $lg) {
            display: none; } }

    .logo-mob {
        display: none;
        @media (max-width: $lg) {
            display: block; } }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-left: 132px;

        @media (max-width: $lg) {
            display: none; }

        p {
            font-size: 18px;
            color: $accent; }

        a, div {
            display: flex;
            align-items: center;
            gap: 16px;

            svg {
                display: block; } } }

    .nav {
        margin-left: 132px;

        @media (max-width: $lg) {
            margin-left: initial; }

        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: $lg) {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                max-width: 236px;
                column-gap: 24px; }

            a {
                font-size: 18px;
                color: $accent;

                @media (max-width: $lg) {
                    font-weight: 700; }

                &:hover {
                    color: $accent-2; } } } } }
