.promo {
    &__top {
        position: relative;
        display: flex;
        background: linear-gradient(84.27deg, rgba(250, 241, 237, 0.5) 0%, #E6F2FF 100%);

        .container-main {
            display: flex;
            width: 100%;
            padding-top: 45px;
            padding-bottom: 83px;
            justify-content: space-between;
            gap: 40px;

            @media (max-width: $lg) {
                padding-top: 30px;
                padding-bottom: 30px;
                flex-direction: column-reverse;
                gap: 20px; } } }

    &__text {
        max-width: 520px;
        margin-top: 147px;

        @media (max-width: $lg) {
            margin-top: 0; }

        p {
            font-size: 34px;
            line-height: 58px;
            margin-bottom: 10px;
            color: $accent;
            font-family: 'Montserrat';

            @media (max-width: $lg) {
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 24px; }

            &.dop {
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 0;
                font-family: 'Open Sans';

                @media (max-width: $lg) {
                    font-size: 18px; } } }

        h1 {
            color: $accent;
            font-weight: 800;
            font-size: 50px;
            line-height: 65px;
            margin: 0;
            margin-bottom: 10px;
            font-family: 'Montserrat';

            @media (max-width: $lg) {
                font-size: 24px;
                line-height: 31px; } } }

    picture {
        width: 100%;
        height: 417px;

        @media (max-width: $lg) {
            height: initial; } }

    img {
        border-radius: 10px;
        width: 100%;

        @media (max-width: $md) {
            max-height: 280px; }

        @media (max-width: 413px) {
            max-height: 220px; } }

    .promo__img-d {
        max-width: 790px;
        width: 100%;

        @media (max-width: $lg) {
            max-width: 100%;
            height: auto; } }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        padding: 87px 0;

        @media (max-width: $lg) {
            flex-wrap: wrap;
            gap: 12px;
            padding: 30px 0; }

        div {
            max-width: 322px;
            width: 100%;
            display: flex;
            gap: 15px;
            align-items: center;

            @media (max-width: $lg) {
                gap: 12px; }

            @media (max-width: 767px) {
                max-width: 100%; }

            br {
                @media (max-width: $lg) {
                    display: none; } }

            svg {
                min-width: 84px;

                @media (max-width: 767px) {
                    max-width: 62px;
                    min-width: 62px; }

                @media (max-width: 413px) {
                    max-width: 42px;
                    min-width: 42px; } }

            p {
                font-size: 20px;
                line-height: 27px;

                @media (max-width: 767px) {
                    font-size: 18px; } } } } }
