.navy {
    padding-top: 50px;
    padding-bottom: 70px;
    background: linear-gradient(84.27deg, rgba(250, 241, 237, 0.5) 0%, #E6F2FF 100%);

    @media (max-width: $lg) {
        padding: 46px 0; }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;

        h2 {
            margin: 0; } }

    .swiper-slide {
        h3 {
            margin-bottom: 4px;
            font-size: 20px;
            font-weight: 700; }

        strong {
            margin-right: 4px; }

        p {
            margin-bottom: 12px; }

        hr {
            max-width: 370px;
            margin: 12px 0; }

        picture {
            @media (max-width: $md) {
                width: 100%;

                img {
                    aspect-ratio: 2 / 1.3; } } } } }
